
import React, { Fragment } from 'react';
import RensRoute from '../../rensRouter/rensRoute';

import Login from './login';
import Register from './register';

import '../../scss/user.scss';

export default class User extends React.Component {
    render() {
        return (
            <Fragment>
                <RensRoute path="/user/login"><Login /></RensRoute>
                <RensRoute path="/user/register"><Register /></RensRoute>
            </Fragment>
        );
    }
}
