
import React from 'react';

export default class AddButton extends React.Component {
    render() {
        return (
            <div className="addButton" onClick={this.props.onClick}>
                <div className="line one"></div>
                <div className="line two"></div>
            </div>
        );
    }
}