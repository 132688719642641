
import React from 'react';
import autobind from 'class-autobind';

import DeleteButton from '../base/buttons/deleteButton';
import EditButton from '../base/buttons/editButton'

import socket from '../utils/sockets';
import Navigate from '../../rensRouter/rensNavigate';

export default class Store extends React.Component {
    constructor(props) {
        super(props);
        autobind(this);
    }

    onEditClick() {
        Navigate(`/store/edit/${this.props.id}`);
    }

    onDeleteClick() {
        socket.emit('removeStore', { id: this.props.id });
    }

    render() {
        return (
            <li className="store">
                <div className="info">
                    <div style={{ backgroundColor: this.props.color }} className="color"></div>
                    <p>{this.props.name}</p>
                </div>

                <div className="buttons">
                    <EditButton onClick={this.onEditClick}/>
                    <DeleteButton onClick={this.onDeleteClick}/>
                </div>
            </li>
        );
    }
}