
import React from 'react';
import autobind from 'class-autobind';

import ExpandIcon from '../icons/expandIcon';

export default class ExpandButton extends React.Component {
    constructor(props) {
        super(props);
        autobind(this);
    }

    getStyle() {
        const style = {
            outline: "none", 
            transform: "rotate(0deg)", 
            transition: "0.2s linear"
        };

        if (this.props.expanded) {
            style.transform = "rotate(90deg)";
            return style;
        }

        return style;
    }

    render() {
        return (
            <button style={this.getStyle()} className="expand" onClick={this.props.onClick}>
                <ExpandIcon width="24px" height="24px"></ExpandIcon>
            </button>
        );
    }
}