import React from 'react';
import { RensAlertContainer } from './rensAlert/rensAlert';
import RensRoute from './rensRouter/rensRoute';
import ContextManager from './context/contextManager';
import UserContextProvider from './context/userContextProvider';

import User from './components/user/user';
import Main from './components/main/main';
import Categories from './components/categories/categories';
import Stores from './components/stores/stores';
import Products from './components/products/products';
import EditProduct from './components/products/editProduct';
import HistoryComp from './components/history/history';

import Header from './components/base/header';
import Footer from './components/base/footer';

import './scss/app.scss';
import './scss/user.scss';

import EditStore from './components/stores/editStore';
import EditCategory from './components/categories/editCategory';
import PrivateRoute from './components/privateRoute';

export default class App extends React.Component {
    render() {
      return (
        <div className="app">
            <RensAlertContainer/>
              <div className="mainContent">
                <UserContextProvider ref={ContextManager.getContextProviderRef()}>
                  <Header></Header>

                  <PrivateRoute path="/"><Main /></PrivateRoute>

                  <PrivateRoute path="/stores"><Stores /></PrivateRoute>
                  <PrivateRoute path="/store/edit/:id"><EditStore /></PrivateRoute>

                  <PrivateRoute path="/categories"><Categories /></PrivateRoute>
                  <PrivateRoute path="/category/edit/:id"><EditCategory /></PrivateRoute>

                  <PrivateRoute path="/products"><Products /></PrivateRoute>
                  <PrivateRoute path="/product/edit/:id"><EditProduct /></PrivateRoute>
                  
                  <PrivateRoute path="/history"><HistoryComp /></PrivateRoute>

                  <RensRoute path="/user/*"><User /></RensRoute>
                </UserContextProvider>
              </div>
            <Footer></Footer>
        </div>
      );
    }
}