
import React from 'react';
import DeleteButton from '../base/buttons/deleteButton';
import autobind from 'class-autobind';
import socket from '../utils/sockets';
import EditButton from '../base/buttons/editButton';
import Navigate from '../../rensRouter/rensNavigate';

export default class Category extends React.Component {
    constructor(props) {
        super(props);
        autobind(this);
    }

    onEditClick() {
        Navigate(`/category/edit/${this.props.id}`);
    }

    onDeleteClick() {
        socket.emit('removeCategory', { id: this.props.id });
    }

    render() {
        return (
            <li className="category">
                <div className="info">
                    <div style={{ backgroundColor: this.props.color }} className="color"></div>
                    <p>{this.props.name}</p>
                </div>
                
                <div className="buttons">
                    <EditButton onClick={this.onEditClick}/>
                    <DeleteButton onClick={this.onDeleteClick}/>
                </div>
            </li>
        );
    }
}