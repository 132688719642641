
import React from 'react';
import autobind from 'class-autobind';
import Navigate from '../../rensRouter/rensNavigate';

import { Context } from '../../context/userContextProvider';

const MOBILE_HEADER = 1360;

export default class Header extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        autobind(this);

        this.menu = React.createRef();
    }

    onClickLogo() {
        Navigate('/');
    }

    onLogout() {
        this.context.logout();
    }

    getAccountInfo() {
        if (this.context.loggedIn) {
            return (
                <div className="account">
                    <p>{this.context.user.email}</p>
                    <p className="logout" onClick={this.onLogout}>Logout</p>
                </div>
            );
        }

        return (
            <div className="account">
                <button onClick={() => this.onClickButton('/user/register')}>Register</button> 
                <button onClick={() => this.onClickButton('/user/login')}>Log in</button> 
            </div>
        );
    }

    onClickButton(route) {
        if (!this.menu.current) return;

        const visible = this.menu.current.classList.contains('show');
        if (visible && window.innerWidth <= MOBILE_HEADER) {
            this.menu.current.classList.toggle('show');
        }

        Navigate(route);
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
        window.addEventListener('click', this.onClick);

        this.onResize();
    }

    onResize() {
        if (this.menu.current) {
            if (window.innerWidth > MOBILE_HEADER) this.menu.current.classList.add('show');
            else this.menu.current.classList.remove('show');
        }
    }

    toggleMenu() {
        if (!this.menu.current) return;
        this.menu.current.classList.toggle('show');
    }

    render() {
        return (
            <header className="header">
                <div className="left">
                    <div className="logo" onClick={this.onClickLogo}></div>

                    <div className="hamburger" onClick={this.toggleMenu}>
                        <span className="line one"></span>
                        <span className="line two"></span>
                        <span className="line three"></span>
                    </div>
                </div>
                

                <div className="right" ref={this.menu}>
                    <nav className="navigation">
                        <button onClick={() => this.onClickButton('/')}>Home</button>
                        <button onClick={() => this.onClickButton('/categories')}>Categories</button>
                        <button onClick={() => this.onClickButton('/stores')}>Stores</button>
                        <button onClick={() => this.onClickButton('/products')}>Products</button>
                        <button onClick={() => this.onClickButton('/history')}>History</button>
                    </nav>

                    {this.getAccountInfo()}
                </div>
            </header>
        );
    }
}
