
import React, { useState, useContext, useRef } from 'react';
import Socket from '../utils/sockets';

import Grouping from './grouping';
import ProductSuggestion from './productSuggestion';

import HideShowButton from '../base/buttons/hideShowButton';

import priceIcon from '../../images/money.png';
import countIcon from '../../images/count.png';
import weightIcon from '../../images/weight.png';

import RensAlert from '../../rensAlert/rensAlert';

import { Context } from '../../context/userContextProvider';
import ContextManager from '../../context/contextManager';
import Navigate from '../../rensRouter/rensNavigate';

import '../../scss/list.scss';

const SORT_TYPE = {
    Category: 0,
    Store: 1,
    NameAZ: 2,
    NameZA: 3,
    PriceLowHigh: 4,
    PriceHighLow: 5
};

export default function Main() {
    const [suggestions, setSuggestions] = useState([]);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);

    const [addName, setAddName] = useState('');
    const [top, setTop] = useState(true);
    const [bottom, setBottom] = useState(false);

    const addBox = useRef();
    const suggestionBox = useRef();

    const context = useContext(Context);

    function onAddBoxChange(e) {
        const n = typeof e === 'string' ? e : e.target.value;
        const name = n.toUpperCase();

        if (n.length >= 2) {
            const suggestions = filteredSuggestions.filter((p) => p.name.toUpperCase().includes(name));
            setSuggestions(suggestions);
            setAddName(n);
            return;
        }

        setSuggestions([]);
        setFilteredSuggestions(context.products);
        setAddName(n);
    }

    function clearList() {
        RensAlert.confirm({
            title: 'Are you sure?',
            text: 'You want to clear all the crossed out items and add them to your purchases?',
            accept: 'yes',
            decline: 'no',
            onAccept: () => {
                Socket.emit('clearList');
            }
        });
    }

    function onSuggestionClick(id) {
        const product = suggestions.find((s) => s.id === id);
        setSuggestions([]);
        setFilteredSuggestions(context.products);
        setAddName("");
        Socket.emit('addToList', { name: product.name });
    }

    const topVisible = top ? ' visible' : '';
    const bottomVisible = bottom ? ' visible' : '';

    const groupings = [];
    context.groupings.forEach((value, key) => { 
        groupings.push(<Grouping key={key + Math.random()} name={key} products={value} color={ContextManager.getColorByName(key)} />);
    });

    return (
        <main className="list">
            <div className="products">
                {context.list.length === 0 && <h2>Your list is empty <span role="img" aria-label="sad emoji">😥</span></h2>}
                {groupings}
            </div>

            <div className={'top' + topVisible}>
                <HideShowButton visible={top} onClick={() => setTop(!top)} />

                <h2>Add and Sort</h2>

                <form autoComplete="off" className="add">
                    <div className="input">
                        <input value={addName} ref={addBox} placeholder="Add Something to the list" onClick={() => onAddBoxChange(addName)} onChange={onAddBoxChange} />
                        <div ref={suggestionBox} className="suggestions">
                            {suggestions.map((s) => <ProductSuggestion key={s.id} {...s} onClick={onSuggestionClick} />)}
                            {suggestions.length === 0 && addName.length >= 2 && <p className="nothing-found">No Products could be Found</p>}
                            {suggestions.length === 0 && addName.length >= 2 && <button className="add-product-button" onClick={() => Navigate(`/products?add-name=${addName}`)}>Add "{addName}" as a Product</button>}
                        </div>
                    </div>
                </form>

                <div className="search">
                    <h3>Sort list by</h3>

                    <select value={context.sortType} onChange={(e) => ContextManager.changeSortType(e)}>
                        <option value={SORT_TYPE.Category}>Category</option>
                        <option value={SORT_TYPE.NameAZ}>Name (A - Z)</option>
                        <option value={SORT_TYPE.NameZA}>Name (Z - A)</option>
                        <option value={SORT_TYPE.Store}>Store</option>
                        <option value={SORT_TYPE.PriceLowHigh}>Price (Low - High)</option>
                        <option value={SORT_TYPE.PriceHighLow}>Price (High - Low)</option>
                    </select>
                </div>
            </div>

            <div className={'bottom' + bottomVisible}>
                <HideShowButton visible={bottom} onClick={() => setBottom(!bottom)} />

                <h2>Totals</h2>

                <div className="info">
                    <div className="count">
                        <img src={countIcon} alt="count" />

                        <div className="text">
                            <p>Products</p>
                            <p>{context.totalCount}</p>
                        </div>
                    </div>

                    <div className="weight">
                        <img src={weightIcon} alt="weight" />

                        <div className="text">
                            <p>Weight</p>
                            <p>{context.totalWeight}g ({context.totalWeight / 1000}kg)</p>
                        </div>
                    </div>

                    <div className="price">
                        <img src={priceIcon} alt="price" />

                        <div className="text">
                            <p>Price</p>
                            <p>&euro; {context.totalPrice}</p>
                        </div>
                    </div>
                </div>
                <div className="buttons">
                    <button onClick={clearList} className="clear">Clear Items</button>
                </div>
            </div>
        </main>
    );
}