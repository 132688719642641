
import React from 'react';

import weight from '../../images/weight.png';
import money from '../../images/money.png';
import category from '../../images/category.png';
import store from '../../images/store.png';
import autobind from 'class-autobind';

import ExpandButton from '../base/buttons/expandButton';
import EditButton from '../base/buttons/editButton';
import DeleteButton from '../base/buttons/deleteButton';
import socket from '../utils/sockets';

import RensAlert from '../../rensAlert/rensAlert';
import Navigate from '../../rensRouter/rensNavigate';

export default class Product extends React.Component {
    constructor(props) {
        super(props);
        autobind(this);

        this.state = {
            expanded: false
        };
    }

    quantityTypeShortForm() {
        switch (this.props.product.quantity_type) {
            case 'kilogram': return 'kg';
            case 'milliliter': return 'ml';
            case 'piece': return this.props.product.quantity > 1 ? 'pieces' : '';
            case 'liter': return 'l';
            case 'gram': return 'g';
            default: return this.props.product.quantity_type;
        }
    }

    onClickExpand() {
        this.setState({ expanded: !this.state.expanded });
    }

    onClickEdit() {
        Navigate(`/product/edit/${this.props.product.id}`);
    }

    onClickDelete() {
        RensAlert.confirm({
            title: 'Are you sure',
            text: `You want to remove '${this.props.product.name}'?`,
            onAccept: () => socket.emit('removeProduct', { id: this.props.product.id })
        });
    }

    render() {
        const bottomStyle = {
            display: this.state.expanded ? 'flex' : 'none'
        };  

        return (
            <li>
                <div className="product">
                    <div className="top">
                        <div className="left">
                            <ExpandButton expanded={this.state.expanded} onClick={this.onClickExpand}/>
                            <p>{this.props.product.name}</p>
                        </div>

                        <div className="buttons">
                            <EditButton onClick={this.onClickEdit}/>
                            <DeleteButton onClick={this.onClickDelete}/>
                        </div>                        
                    </div>

                    <div style={bottomStyle} className="bottom">
                        <div className="left">
                            <div className="weight">
                                <img src={weight} alt="weight"/>
                                <p>{this.props.product.quantity} {this.quantityTypeShortForm()}</p>
                            </div>

                            <div className="category">
                                <img src={category} alt="category"/>
                                <div style={{ backgroundColor: this.props.product.categoryColor }} className="color"></div>
                                <p>{this.props.product.category}</p>
                            </div>
                        </div>

                        <div className="right">
                            <div className="price">
                                <p>&euro; {this.props.product.price.toFixed(2)}</p>
                                <img src={money} alt="price"/>
                            </div>

                            <div className="store">
                                <p>{this.props.product.store}</p>
                                <div style={{ backgroundColor: this.props.product.storeColor }} className="color"></div>
                                <img src={store} alt="store"/>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        );
    }
}