
import React from 'react';
import userContext, { SORT_TYPE } from '../context/userContext';

import Logger from '../components/utils/logger';
import { parseJwt } from '../components/utils/util';
import Socket from '../components/utils/sockets';
import RensAlert from '../rensAlert/rensAlert';
import database from '../components/utils/database';

import Navigate from '../rensRouter/rensNavigate';

class ContextManager {
    constructor() {
        this.logger = new Logger('ContextManager');

        this.contextProviderRef = React.createRef();
        
        this.initCalled = false;
    }

    getUserById(id) {
        return userContext.users?.get(id);
    }

    getMe() {
        return userContext.users?.get(userContext.userId);
    }

    getDecodedToken() {
        return parseJwt(userContext.token);
    }

    async getList() {
        const result = await database.getList();
        if (result.ok) {
            userContext.list = result.data;
            this.update();
        }
    }

    async init() {
        if (this.initCalled) return;
        
        const [ dbCategories, dbStores, dbProducts, dbList ] = await Promise.all([
            database.getAllCategories(),
            database.getAllStores(),
            database.getAllProducts(),
            database.getList()
        ]);

        if (dbCategories.ok) {
            const categories = dbCategories.data;
            categories.sort((a, b) => a.name.localeCompare(b.name));
            userContext.categories = categories;
        }

        if (dbStores.ok) {
            const stores = dbStores.data;
            stores.sort((a, b) => a.name.localeCompare(b.name));
            userContext.stores = stores;
        }

        if (dbProducts.ok) {
            const products = dbProducts.data;
            products.sort((a, b) => a.name.localeCompare(b.name));
            userContext.products = products;
        } 

        if (dbList.ok) {
            userContext.list = dbList.data;
        }

        [ 
            { name: 'category', l: 'categories', cap: 'Category' }, 
            { name: 'store', l: 'stores', cap: 'Store' },
            { name: 'product', l: 'products', cap: 'Product' }
        ].forEach(({ name, l, cap }) => {
            Socket.on(name + 'Added', (item) => {
                const list = userContext[l];
                list.push(item);
                list.sort((a, b) => a.name.localeCompare(b.name));
                userContext[l] = list;

                this.update();
            });

            Socket.on(name + 'Removed', (id) => {
                const list = userContext[l];
                const removeIndex = list.findIndex((c) => c.id === id);
                if (removeIndex !== -1) list.splice(removeIndex, 1);
                userContext[l] = list;

                this.update();
            });

            Socket.on(name + 'Edited', (data) => {
                const item = data[name];
                const list = userContext[l];

                list.splice(list.findIndex((i) => i.id === item.id), 1, item);
                this.refresh();

                if (data.editor) {
                    // We are the editor
                    RensAlert.popup({
                        title: 'Success',
                        text: `${cap} was updated successfully!`,
                        time: 3500
                    });

                    Navigate(`/${l}`);
                }
            });
        });

        Socket.on('addedToList', (product) => {
            const list = userContext.list;
            list.push(product);
            userContext.list = list;

            this.update();
        });

        Socket.on('removedFromList', (product) => {
            const list = userContext.list;
            const removeIndex = list.findIndex((p) => p.id === product.id);
            if (removeIndex !== -1) list.splice(removeIndex, 1);
            userContext.list = list;

            this.update();
        });

        Socket.on('countIncreased', (product) => {
            const foundProduct = userContext.list.find((p) => p.id === product.id);
            if (foundProduct) foundProduct.count++;

            this.update();
        });
        
        Socket.on('countDecreased', (product) => {
            const foundProduct = userContext.list.find((p) => p.id === product.id);
            if (foundProduct) foundProduct.count--;

            this.update();
        });

        Socket.on('productChecked', (product) => {
            const foundProduct = userContext.list.find((p) => p.id === product.id);
            if (foundProduct) foundProduct.checked = product.checked;

            this.update();
        });

        Socket.on('listCleared', () => {
            RensAlert.popup({
                title: 'Success',
                text: 'Your list has been succesfully cleared and saved to history.',
                time: 6000,
                onClose: () => window.location.reload()
            });
        });

        this.update();
        this.initCalled = true;
    }

    createSimpleGrouping(list, groupings, type) {
        let groupName = '', group = [];

        list.sort((a, b) => a[type].localeCompare(b[type]));
        list.forEach((p) => {
            if (p[type] !== groupName && groupName !== '') {
                groupings.set(groupName, group);
                group = [];
            }

            groupName = p[type];
            group.push(p);
        });
        groupings.set(groupName, group);
    }

    createNameMapping(list, groupings, reverse = false) {
        if (reverse) {
            list.sort((a, b) => b.name.localeCompare(a.name));
            groupings.set('Name A - Z', list);
        }
        else {
            list.sort((a, b) => a.name.localeCompare(b.name));
            groupings.set('Name Z - A', list);
        }
    }

    createPriceMapping(list, groupings, reverse = false) {
        if (reverse) {
            list.sort((a, b) => b.price - a.price);
            groupings.set('Price High to Low', list);
        }
        else {
            list.sort((a, b) => a.price - b.price);
            groupings.set('Price Low to High', list);
        }
    }

    update() {
        userContext.totalPrice = userContext.list.reduce((prev, cur) => prev + (cur.price * cur.count), 0).toFixed(2);
        userContext.totalCount = userContext.list.reduce((prev, cur) => prev + cur.count, 0);
        userContext.totalWeight = userContext.list.reduce((prev, cur) => {
            let weight = 0;
            if (cur.quantity_type === 'gram' || cur.quantity_type === 'milliliter') weight = cur.quantity;
            else if (cur.quantity_type === 'liter') weight = cur.quantity * 1000;
            return prev + (weight * cur.count);
        }, 0);

        const list = userContext.list;
        const groupings = new Map();

        switch (userContext.sortType) {
            case SORT_TYPE.Store:
                this.createSimpleGrouping(list, groupings, 'store');
                break;
            case SORT_TYPE.NameAZ:
            case SORT_TYPE.NameZA:
                this.createNameMapping(list, groupings, userContext.sortType === SORT_TYPE.NameZA);
                break;
            case SORT_TYPE.PriceLowHigh:
            case SORT_TYPE.PriceHighLow:
                this.createPriceMapping(list, groupings, userContext.sortType === SORT_TYPE.PriceHighLow);
                break;
            default:
                this.createSimpleGrouping(list, groupings, 'category');
                break;
        }
        
        userContext.groupings = groupings;
        this.refresh();
    }

    changeSortType(e = null) {
        userContext.sortType = e === null ? Number(userContext.sortType) : Number(e.target.value);
        this.update();
    }

    getColorByName(name) {
        const category = userContext.categories.find((c) => c.name === name);
        if (category) return category.color;

        const store = userContext.stores.find((s) => s.name === name);
        if (store) return store.color;

        return 'rgb(88, 147, 236)';
    }

    getContextProviderRef() {
        return this.contextProviderRef;
    }

    refresh() {
        const current = this.contextProviderRef.current;
        if (current) current.onUserUpdate();
    }
}

const contextManager = new ContextManager();
export default contextManager;
