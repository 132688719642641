
import React from 'react';
import autobind from 'class-autobind';

import db from '../utils/database';
import { Context } from '../../context/userContextProvider';

import Navigate from '../../rensRouter/rensNavigate';
import Storage from '../utils/storage';

export default class Login extends React.Component {
    static contextType = Context;

    constructor() {
        super();
        autobind(this);

        this.state = {
            email: '',
            pass: '',
            errorMsg: '',
            rememberMe: false
        };
    }

    async onSubmit(e) {
        e.preventDefault();
        
        const result = await db.login(this.state.email, this.state.pass);
        if (result.ok) {
            Storage.setRememberMe(this.state.rememberMe);
            this.context.afterLogin(result.data.token);
        }
        else {
            this.setState({ errorMsg: result.data });
        }
    }

    componentDidMount() {
        if (this.context.loggedIn) Navigate('/');
    }

    render() {
        return (
            <form className="login" onSubmit={this.onSubmit}>
                <h2>Login</h2>

                <input 
                    className="email" value={this.state.email} type="email" placeholder="Email" required autoComplete="email"
                    onChange={(e) => this.setState({ email: e.target.value })}
                /> 
                <input 
                    className="pass" value={this.state.pass} type="password" placeholder="Password" required autoComplete="password"
                    onChange={(e) => this.setState({ pass: e.target.value })}
                />

                <div className="bottom">
                    <p className="error">{this.state.errorMsg}</p>

                    <div className="controls">
                        <input type="submit" value="Login"/>
                        <input id="rememberMe" type="checkbox" checked={this.state.rememberMe} value={this.state.rememberMe} onChange={(e) => this.setState({ rememberMe: !this.state.rememberMe })} />
                        <label htmlFor="rememberMe">Remember me</label>
                    </div>
                </div>
            </form>
        );
    }
}
