
import React from 'react';

import db from '../utils/database';
import autobind from 'class-autobind';
import socket from '../utils/sockets';

import ColorPicker from '../base/previewColorPicker';

import '../../scss/editStore.scss';
import Navigate from '../../rensRouter/rensNavigate';
import { getIdFromUrl } from '../utils/util';

export default class EditStore extends React.Component {
    constructor(props) {
        super(props);
        autobind(this);

        this.state = {
            id: getIdFromUrl(),
            name: '',
            color: '#e7e7e7',
            error: false,
            showPicker: false,
            errorMsg: ''
        };
    }

    async componentDidMount() {
        const dbStore = await db.getStore(this.state.id);
        if (dbStore.ok) this.setState({ name: dbStore.data.name, color: dbStore.data.color, error: false, errorMsg: '' });
        else {
            this.setState({ error: true, errorMsg: dbStore.data });
        }
    }

    onEditClick() {
        socket.emit('editStore', { id: this.state.id, name: this.state.name, color: this.state.color });
    }

    onColorChange(c) {
        this.setState({ color: c.hex });
    }

    render() {
        if (this.state.error) {
            return (
                <div className="edit store">
                    <h2>Edit Store</h2>
                    <h3 className="error">{this.state.errorMsg}</h3>
                </div>
            );
        } 
        return (
            <div className="edit store">
                <h2>Edit Store</h2>

                <div className="content">
                    <input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })}/>
                    <ColorPicker 
                        editing={this.state.showPicker} color={this.state.color} onColorChange={this.onColorChange} 
                        onClickColor={() => this.setState({ showPicker: true })} onClickOutside={() => this.setState({ showPicker: false })}
                    />
                </div>

                <div className="buttons">
                    <button onClick={this.onEditClick}>Edit</button>
                    <button onClick={() => Navigate('/stores')}>Back</button>
                </div>
            </div>
        );
    }
}