
import React from 'react';
import autobind from 'class-autobind';

import db from '../utils/database';

import RensAlert from '../../rensAlert/rensAlert';
import Navigate from '../../rensRouter/rensNavigate';

export default class Register extends React.Component {
    constructor(props) {
        super(props);
        autobind(this);
        
        this.state = {
            email: '',
            pass: '',
            repeatPass: '',
            error: ''
        };
    }

    async onSubmit(event) {
        event.preventDefault();

        const e = this.state.email;
        const p = this.state.pass;
        const pR = this.state.repeatPass;

        if (p !== pR) {
            RensAlert.popup({
                title: 'Oops',
                text: 'Your password doesn\'t match the repeat password',
                time: 4000
            });
            return;
        }

        if (e.length < 2) {
            RensAlert.popup({
                title: 'Oops',
                text: 'Your emailaddress is invalid',
                time: 4000
            });
            return;
        }

        if (p.length < 8) {
            RensAlert.popup({
                title: 'Oops',
                text: 'Your password is too short',
                time: 4000
            });
            return;
        }

        if (p.length > 64) {
            RensAlert.popup({
                title: 'Oops',
                text: 'Your password is too long',
                time: 4000
            });
            return;
        }

        const response = await db.register({ email: e, password: p });
        if (response.ok) {
            RensAlert.popup({
                title: 'Yay',
                text: 'Your account was successfully registered!',
                time: 4000
            });
            Navigate('/user/login');
        }
        else {
            this.setState({ errorMsg: response.data });
        }
    }

    render() {
        return (
            <form onSubmit={this.onSubmit} className="register">
                <h2>Register</h2>

                <input 
                    className="email" type="email" placeholder="Email" required autoComplete="email"
                    onChange={(e) => this.setState({ email: e.target.value})}/
                >
                <input 
                    className="pass" type="password" placeholder="Password" required autoComplete="new-password"
                    onChange={(e) => this.setState({ pass: e.target.value})}
                />
                <input 
                    className="passRepeat" type="password" placeholder="Repeat Password" required autoComplete="new-password"
                    onChange={(e) => this.setState({ repeatPass: e.target.value})}/>
                <input type="submit" value="Register"/>
            </form>
        );
    }
}