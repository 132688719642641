
import React from 'react';
import autobind from 'class-autobind';

import HistoryEntry from './historyEntry';

import db from '../utils/database';
import PageNav from '../base/pageNav';

import '../../scss/history.scss';

const RESULTS_PER_PAGE = 8;

export default class History extends React.Component {
    constructor(props) {
        super();
        autobind(this);

        this.state = {
            count: '',
            currentPage: 0,
            pageCount: 0,
            products: [],
            entries: []
        };
    }

    async componentDidMount() {
        const [ dbCount, dbProducts ] = await Promise.all([
            db.getHistoryCount(),
            db.getAllProducts()
        ]);

        if (dbCount.ok && dbProducts.ok) {
            const count = dbCount.data.count;
            const pageCount = Math.ceil(Math.max(count / RESULTS_PER_PAGE, 1));
            const products = dbProducts.data;

            this.getHistory(0, products);
            this.setState({ count, pageCount, products });
        }
    }

    async getHistory(from, products) {
        const dbHistory = await db.getHistory(from, RESULTS_PER_PAGE);
        if (dbHistory.ok) {
            const entries = [];
            const dbHistoryData = dbHistory.data;
            dbHistoryData.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
            
            for (let i = 0; i < dbHistoryData.length; i++) {
                const h = dbHistoryData[i];

                const productEntries = JSON.parse(h.products);
                const historyProducts = [];

                productEntries.forEach((pe) => {
                    const product = products.find((p) => p.id === pe.id);
                    product.price = pe.price;
                    product.count = pe.count;

                    historyProducts.push(product);
                });

                const history =  {
                    id: h.id,
                    user_id: h.user_id,
                    products: historyProducts,
                    timestamp: new Date(h.timestamp)
                };

                entries.push(<HistoryEntry key={history.id} history={history} first={i === 0} last={i === dbHistoryData.length - 1}/>);
            }

            this.setState({ products, entries });
        }
    }

    onPageNumberClick(index) {
        if (index === this.state.currentPage) return;

        this.getHistory(index * RESULTS_PER_PAGE, this.state.products);
        this.setState({ currentPage: index });
    }

    onLeftArrowClick() {
        const index = this.state.currentPage - 1;
        this.getHistory(index * RESULTS_PER_PAGE, this.state.products);
        this.setState({ currentPage: index });
    }

    onRightArrowClick() {
        const index = this.state.currentPage + 1;
        this.getHistory(index * RESULTS_PER_PAGE, this.state.products);
        this.setState({ currentPage: index });
    }

    render() {
        return (
            <div className="history">
                <h2>History</h2>
                <h3>Total entries: {this.state.count}</h3>

                {this.state.entries}

                <PageNav currentPage={this.state.currentPage} pageCount={this.state.pageCount} onPageClick={this.onPageNumberClick} onLeftClick={this.onLeftArrowClick} onRightClick={this.onRightArrowClick}/>
            </div>
        );
    }
}