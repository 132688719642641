import React, { Component } from 'react';
import PropTypes from 'prop-types';

import autobind from 'class-autobind';
import Logger from '../components/utils/logger';
import userContext from './userContext';

export const Context = React.createContext(userContext);

export default class UserContextProvider extends Component {
	static get propTypes() {
		return {
			children: PropTypes.any
		};
	}

	constructor() {
		super();

		this.state = {
			user: null,
			settings: {}
		};

		this.log = new Logger('UserContextProvider');
		autobind(this);
	}

	async isLoggedIn() {
		const state = await userContext.isLoggedIn();
		this.setState({ ...userContext });
		return state;
	}

	async afterLogin(token) {
		await userContext.afterLogin(token);
		this.setState({ ...userContext });
	}

	setOnlineState(s) {
		userContext.setOnlineState(s);
		this.setState({ ...userContext });
	}

	onUserUpdate() {
		this.setState({ ...userContext });
	}

	logout() {
		userContext.logout();
		this.setState({ ...userContext });
	}

	render() {
		const context = {
			afterLogin: this.afterLogin,
			logout: this.logout,
			isLoggedIn: this.isLoggedIn,
			...this.state,
		};

		return (
			<Context.Provider value={context}>
				{this.props.children}
			</Context.Provider>
		);
	}
}
