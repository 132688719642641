
import React from 'react';

export default class AddButton extends React.Component {
    render() {
        const visible = !this.props.visible ? ' visible' : '';

        return (
            <div className={'hideShowButton' + visible} onClick={this.props.onClick}>
                <div className="line one"></div>
                <div className="line two"></div>
            </div>
        );
    }
}