
import React from 'react';

import { shortQuantity, shortQuantityType } from '../utils/util';

export default class ProductSuggestion extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: ''
        };
    }

    componentDidMount() {
        this.setState({ name: `${shortQuantity(this.props.quantity, this.props.quantity_type)} ${shortQuantityType(this.props.quantity_type)} ${this.props.name}`});
    }

    render() {
        return (
            <div className="suggestion" onClick={() => this.props.onClick(this.props.id)}>
                <p>{this.state.name}</p>
            </div>
        );
    }
}