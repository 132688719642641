import { parseJwt } from '../components/utils/util';
import db from '../components/utils/database';
import Logger from '../components/utils/logger';

import Storage from '../components/utils/storage';

import ContextManager from './contextManager';
import Navigate from '../rensRouter/rensNavigate';
import Socket from '../components/utils/sockets';

export const SORT_TYPE = {
    Category: 0,
    Store: 1,
    NameAZ: 2,
    NameZA: 3,
    PriceLowHigh: 4,
    PriceHighLow: 5
};

class UserContext {
	constructor() {
		this.token = '';

		this.user = {};
		this.loggedIn = false;

		this.categories = [];
		this.stores = [];
		this.products = [];
		this.list = [];
		this.groupings = [];

		this.sortType = SORT_TYPE.Category;
		this.totalPrice = 0;
		this.totalCount = 0;
		this.totalWeight = 0;

		this.log = new Logger('UserContext');
		this.setupSocketsCalled = false;
	}

	clear() {
		this.token = '';
		this.loggedIn = false;
	}

	async afterLogin(token) {
		this.log.debug('afterLogin() with token:', token);

		this.token = `Bearer ${token}`;
		const u = parseJwt(token);
		
		this.user = u;
		this.loggedIn = true;

		Storage.store('token', this.token);
		window.location.href = "/";
	}

	async isLoggedIn() {
		if (this.token) return true;

		await this.onStart();
		return this.token;
	}

	async onStart() {
		this.log.debug('onStart()');
		if (this.loggedIn) return;

		const token = Storage.getString('token');
		
		if (token) {
			this.log.debug('Token found');
			const jwt = token.substr(7, token.length);

			this.token = token;
			const dbResult = await db.isLoggedIn();
			this.loggedIn = dbResult.ok;

			if (this.loggedIn) {
				this.log.debug('User is logged in');
				this.user = parseJwt(jwt);
				this.token = token;
				this.jwt = jwt;

				Socket.login(jwt);

				ContextManager.init();
				ContextManager.refresh();
				return;
			}

			Navigate('/');
			this.log.debug('User not logged in or invalid user found in storage, clearing storage');
			Storage.remove('token');
		}
	}

	logout() {
		this.log.debug('logout()');

		Storage.remove('token');
		Storage.remove('uuid');
		Storage.setRememberMe(false);

		Socket.logout();
		
		window.location.href = "/";
	}
}

const userContext = new UserContext();
export default userContext;
