
import React, { useEffect } from 'react';
import { SketchPicker as ColorPicker } from 'react-color';

export default function PreviewColorPicker(props) {
    const { onClickOutside } = props;

    useEffect(() => {
        function onClickHandler(e) {
            const picker = document.querySelector('.picker');
            if (!e.path.includes(picker)) onClickOutside();
        }

        window.addEventListener('click', onClickHandler);
        return () => { window.removeEventListener('click', onClickHandler); }
    }, [ onClickOutside ]);

    let pickerWidth = window.innerWidth * 0.95;
    if (pickerWidth > 400) pickerWidth = 400;

    const pickerStyle = {
        width: pickerWidth,
        margin: 'auto ' + (window.innerWidth - pickerWidth) / 2 + 'px'
    };

    return (
        <div className="picker">
            {props.editing && <ColorPicker style={pickerStyle} width={pickerWidth} color={props.color} onChange={props.onColorChange}/>}
            <div style={{backgroundColor: props.color}} className="colorPreview" onClick={props.onClickColor}>
                {props.color === '#e7e7e7' && <span>Click to pick a color</span>}
            </div>
        </div>
    );
}

/*
export default class PreviewColorPicker extends React.Component {
    constructor(props) {
        super();
        
        this.state = {
            show: false,
            color: props.color
        };

        autobind(this);
    }

    componentDidMount() {
        window.addEventListener('click', this.onClick);
    }

    onClick(e) {
        const preview = document.querySelector('.colorPreview');
        const picker = document.querySelector('.sketch-picker');

        const clickedInside = picker?.contains(e.target);
        const clickedPreview = preview?.contains(e.target);
        
        if (!clickedInside && !clickedPreview) {
            this.setState({ show: false });
        } 
    }

    onPreviewClick() {
        this.setState({ show: true });
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onClick);
    }

    onColorChange(color) {
        this.props.onChange(color.hex);
        this.setState({ color: color.hex });
    }

    render() {
        let pickerWidth = window.innerWidth * 0.95;
        if (pickerWidth > 400) pickerWidth = 400;

        const pickerStyle = {
            width: pickerWidth,
            margin: 'auto ' + (window.innerWidth - pickerWidth) / 2 + 'px'
        };

        return (
            <div className={'picker' + (this.state.show ? ' show' : '')}>
                <div style={{backgroundColor: this.state.color}} className="colorPreview" onClick={this.onPreviewClick}></div>

                {this.state.show ? <ColorPicker style={pickerStyle} width={pickerWidth} color={this.state.color} onChange={this.onColorChange}/> : ''}
            </div>
        );  
    }
}*/