
import autobind from 'class-autobind';
import React, { Component } from 'react'

import RensRoute from '../rensRouter/rensRoute';

import Auth from './user/login';
import { Context } from '../context/userContextProvider';

export default class PrivateRoute extends Component {
    static contextType = Context;

    constructor() {
        super();

        this.state = {
            component: null
        };

        autobind(this);
    }

    async componentDidMount() {
        const loggedIn = await this.context.isLoggedIn();

        if (loggedIn) {
            this.setState({ component: this.props.children });
        }
        else {
            this.setState({ component: <Auth /> });
        }
    }

    render() {
        return (
            <RensRoute path={this.props.path}>
                {this.state.component}
            </RensRoute>
        );
    }
}