
import React from 'react';
import Product from './product';

export default class Grouping extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            products: []
        };
    }

    componentDidMount() {
        const products = [];
        for (let i = 0; i < this.props.products.length; i++) {
            const p = this.props.products[i];
            products.push(<Product key={p.id} color={this.props.color} product={p}/>);
        }

        this.setState({ products });
    }

    render() {
        return (
            <div style={{ backgroundColor: this.props.color}} className={`category ${this.props.name}`}>
                <h2>{this.props.name}</h2>

                {this.state.products}
            </div>
        );
    }
}