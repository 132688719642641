
import userContext from '../../context/userContext';

class Database {
    constructor() {
        this.API_URL = process.env.REACT_APP_API;
    }

    isLoggedIn() {
		return this.get('auth', true);
	}

    register(body) {
        return this.post('auth/register', body, true);
    }

    login(email, password) {
        return this.post('auth/login', { email, password }, true);
    }

    getProduct(id) {
        return this.get(`products/get/${id}`);
    }

    getAllProducts() {
        return this.get('products/all');
    }

    getAllProductsLimited(from, count) {
        return this.get(`products/get/${from}/${count}`);
    }

    getProductCount() {
        return this.get('products/count');
    }

    getCategory(id) {
        return this.get(`categories/get/${id}`);
    }

    getAllCategories() {
        return this.get('categories/all');
    }

    getStore(id) {
        return this.get(`stores/get/${id}`);
    }

    getAllStores() {
        return this.get('stores/all');
    }

    getList() {
        return this.get('products/current');
    }

    getHistory(from, count) {
        return this.get(`history/get/${from}/${count}`);
    }

    getHistoryCount() {
        return this.get(`history/count`);
    }

    async delete(url) {
        var req = {
            method: 'DELETE',
            headers: {
                'Authorization': userContext.token
            }
        };
        return await this.fetchMethod(url, req);
    }

    async put(url, body) {
        var req = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': userContext.token
            }
        };

        if (body !== null) req.body = JSON.stringify(body);
        return await this.fetchMethod(url, req);
    }

    async get(url, ignoreWait = false) {
        var req = { 
            method: 'GET',
            headers: {
                'Authorization': userContext.token
            }
        };
        return await this.fetchMethod(url, req, ignoreWait);
    }

    async post(url, body, ignoreWait = false) { 
        var req = { method: 'POST' };
        req.headers = {
            'Content-Type': 'application/json',
            'Authorization': userContext.token
        };

        if (body !== null) req.body = JSON.stringify(body);
        return await this.fetchMethod(url, req, ignoreWait);
    }

    async fetchMethod(url, req, ignoreWait = false) {
        var res = {};
        
        await fetch(this.API_URL + url, req)
            .then(async (data) => {
                res.status = data.status;
                res.ok = data.ok;
                res.data = res.ok ? await data.json() : await data.text();
            })
            .catch(error => console.error(error));

        return res;
    }
}

const database = new Database();
export default database;