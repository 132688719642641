import React from 'react';
import autobind from 'class-autobind';

export default class Footer extends React.Component {
    constructor(props) {
        super(props);
        autobind(this);
    }

    render() {
        return (
            <footer className="footer">
                <a href="https://cesarpulles.nl">cesarpulles.nl</a>
            </footer>
        );
    }
}
