
import React from 'react';

import AddButton from '../base/buttons/addButton';
import autobind from 'class-autobind';

import socket from '../utils/sockets';
import RensAlert from '../../rensAlert/rensAlert';

import ColorPicker from '../base/previewColorPicker';

import db from '../utils/database';

import Store from './store';

export default class Stores extends React.Component {
    constructor(props) {
        super(props);
        autobind(this);

        this.state = {
            search: '',
            addName: '',
            addColor: '#e7e7e7',
            allStores: [],
            showPicker: false,
            stores: []
        };
    }

    onStoreAdded(store) {
        const allStores = this.state.allStores;

        allStores.push(<Store key={store.id} id={store.id} name={store.name} color={store.color}/>);
        allStores.sort((a, b) => a.props.name.localeCompare(b.props.name));
        this.setState({ allStores, addName: '' }, () => this.filterProducts(this.state.search));
    }

    onStoreRemoved(id) {
        const allStores = this.state.allStores;
        allStores.splice(allStores.findIndex((c) => c.props.id === id), 1);
        this.setState({ allStores }, () => this.filterProducts(this.state.search));
    }

    async componentDidMount() {
        socket.on('storeAdded', this.onStoreAdded);
        socket.on('storeRemoved', this.onStoreRemoved);

        const dbStores = await db.getAllStores();
        if (dbStores.ok) {
            const allStores = [];
            dbStores.data.forEach((c) => allStores.push(<Store key={c.id} id={c.id} name={c.name} color={c.color}/>));

            allStores.sort((a, b) => a.props.name.localeCompare(b.props.name));
            this.setState({ allStores, stores: allStores });
        }
    }

    onAddStore() {
        const name = this.state.addName;

        if (name.length < 2) {
            RensAlert.popup({
                title: 'Oops',
                text: 'The store name should be atleast 2 characters long',
                time: 4000
            });

            return;
        }

        if (name.length > 64) {
            RensAlert.popup({
                title: 'Oops',
                text: 'The store name is too long.',
                time: 4000
            });

            return;
        }

        socket.emit('addStore', { name, color: this.state.addColor });
    }

    filterProducts(search) {
        const stores = this.state.allStores.filter((p) => p.props.name.toUpperCase().includes(search.toUpperCase()));
        this.setState({ search, stores });
    }

    onColorChange(c) {
        this.setState({ addColor: c.hex });
    }

    render() {
        return (
            <div className="stores">
                <h2>Stores</h2>

                <div className="search">
                    <input 
                        type="text" 
                        placeholder='Search' 
                        value={this.state.search} 
                        onChange={(e) => this.filterProducts(e.target.value)}
                    />
                </div>

                <ul>{this.state.stores}</ul>

                <div className="add">
                    <input 
                        className="name" 
                        ref={this.addNameInput}
                        type="text" 
                        value={this.state.addName}
                        placeholder='Name'
                        onChange={(e) => this.setState({ addName: e.target.value })}
                    />

                    <ColorPicker 
                        editing={this.state.showPicker} color={this.state.addColor} onColorChange={this.onColorChange} 
                        onClickColor={() => this.setState({ showPicker: true })} onClickOutside={() => this.setState({ showPicker: false })}
                    /> 
                    <AddButton onClick={this.onAddStore}/>
                </div>
            </div>
        );
    }
}