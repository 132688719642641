
import React from 'react';

import db from '../utils/database';
import socket from '../utils/sockets';

import '../../scss/editProduct.scss';
import autobind from 'class-autobind';

import { getIdFromUrl } from '../utils/util';

export default class EditProduct extends React.Component {
    constructor(props) {
        super(props);
        autobind(this);
    
        this.state = {
            id: getIdFromUrl(),
            name: '',
            category: 0,
            store: 0,
            quantity: 0,
            quantity_type: 'piece',
            price: 0,
            categories: [],
            stores: []
        };

        this.categorySelect = React.createRef();
        this.storeSelect = React.createRef();
        this.quantityTypeSelect = React.createRef();
    }

    async componentDidMount() {
        const [ dbCategories, dbStores, dbProduct ] = await Promise.all([
            db.getAllCategories(),
            db.getAllStores(),
            db.getProduct(this.state.id)
        ]);

        const categories = [], stores = [];

        if (dbCategories.ok) {
            dbCategories.data.forEach((c) => categories.push(<option key={c.id} value={c.id} name={c.name}>{c.name}</option>));
            categories.sort((a, b) => a.props.name.localeCompare(b.props.name));
        }

        if (dbStores.ok) {
            dbStores.data.forEach((s) => stores.push(<option key={s.id} value={s.id} name={s.name}>{s.name}</option>));
            stores.sort((a, b) => a.props.name.localeCompare(b.props.name));
        }

        if (dbProduct.ok) {
            const p = dbProduct.data;   

            this.setState({ categories, stores, name: p.name, quantity: p.quantity, quantity_type: p.quantity_type, category: p.categoryId, store: p.storeId, price: p.price }, () => {
                this.categorySelect.current.value = p.categoryId;
                this.storeSelect.current.value = p.storeId;
                this.quantityTypeSelect.current.value = p.quantity_type;
            });
        }
    }

    onQuantityChange(e) {
        let quantity = e.target.value;
        if (isNaN(quantity)) quantity = 0;

        quantity = Number(quantity);

        if (quantity < 0) quantity = 0;
        if (quantity > 9999) quantity = 9999;

        this.setState({ quantity });
    }

    onPriceChange(e) {
        let price = e.target.value;
        if (isNaN(price)) price = 0;

        price = Number(price);

        if (price < 0)      price = 0;
        if (price > 9999)   price = 9999;

        price = price.toFixed(2);
        this.setState({ price });
    }

    onEdit() {
        socket.emit('editProduct', { 
            id: this.state.id, name: this.state.name, category_id: this.state.category, store_id: this.state.store,
            quantity: this.state.quantity, quantity_type: this.state.quantity_type, price: this.state.price
        });
    }

    render() {
        return (
            <div className="edit">
                <h2>Edit Product</h2> 

                <input 
                    className="name" 
                    ref={this.addNameInput}
                    type="text" 
                    value={this.state.name}
                    placeholder='Name'
                    onChange={(e) => this.setState({ name: e.target.value })}
                />

                <div className="selects">
                    <select className="category" ref={this.categorySelect} onChange={(e) => this.setState({ category: e.target.value })}>
                        {this.state.categories}
                    </select>

                    <select className="store" ref={this.storeSelect} onChange={(e) => this.setState({ store: e.target.value })}>
                        {this.state.stores}
                    </select>
                </div>

                <div className="quantity">
                    <input
                        className="quantity"
                        ref={this.addQuantityInput}
                        type="number"
                        value={this.state.quantity}
                        placeholder='Quantity'
                        onChange={(e) => this.setState({ quantity: e.target.value })}
                        onBlur={(e) => this.onQuantityChange(e)}
                    />

                    <select className="quantityType" ref={this.quantityTypeSelect} onChange={(e) => this.setState({ quantity_type: e.target.value })}>
                        <option value='piece'>piece(s)</option>
                        <option value='gram'>gram(s)</option>
                        <option value='kilogram'>kilogram(s)</option>
                        <option value='milliliter'>milliliter(s)</option>
                        <option value='liter'>liter(s)</option>
                    </select>
                </div>

                <div className="bottom">
                    <input 
                        className="price" 
                        ref={this.addPriceInput}
                        type="number" 
                        placeholder='Price' 
                        value={this.state.price}
                        onChange={(e) => this.setState({ price: e.target.value })}
                        onBlur={(e) => this.onPriceChange(e)}
                    />

                    <button onClick={this.onEdit}>Edit</button>
                </div>
            </div>
        );
    }
}