
import React from 'react';
import autobind from 'class-autobind';
import RensAlert from '../../rensAlert/rensAlert';

import Product from './product';

import socket from '../utils/sockets';
import db from '../utils/database';

import '../../scss/products.scss';
import AddButton from '../base/buttons/addButton';

import PageNav from '../base/pageNav';
import { getParam } from '../utils/util';

const PRODUCTS_PER_PAGE = 8;

export default class Products extends React.Component {
    constructor(props) {
        super(props);
        autobind(this);

        this.state = {
            allProducts: [],
            visibleProducts: [],
            categories: [],
            stores: [],
            search: '',
            addName: '',
            addCategory: 0,
            addStore: 0,
            addQuantity: '',
            addQuantityType: 'piece',
            addPrice: '',
            currentPage: 0
        };

        this.addNameInput = React.createRef();
        this.addQuantityInput = React.createRef();
        this.addPriceInput = React.createRef();
    }

    onProductAdded(product) {
        const allProducts = this.state.allProducts;
        allProducts.push(
            <Product key={product.id} id={product.id} product={product}/>
        );
        allProducts.sort((a, b) => a.props.product.name.localeCompare(b.props.product.name));
        this.setState({ allProducts, addName: '', addQuantity: '', addPrice: '' }, () => this.getVisibleProducts(this.state.allProducts, this.state.search));
    }

    onProductRemoved(id) {
        const allProducts = this.state.allProducts;
        const removeProduct = allProducts.find((p) => p.props.id === id);

        allProducts.splice(allProducts.indexOf(removeProduct), 1);
        this.setState({ allProducts }, () => this.getVisibleProducts(this.state.allProducts, this.state.search));
    }

    async componentDidMount() {
        socket.on('productAdded', this.onProductAdded);
        socket.on('productRemoved', this.onProductRemoved);

        const addName = getParam('add-name');
        if (addName) {
            this.setState({ addName });
        }

        const [ dbCount, dbCategories, dbStores, dbProducts ] = await Promise.all([
            db.getProductCount(),
            db.getAllCategories(),
            db.getAllStores(),
            db.getAllProducts()
        ]);

        if (dbCount.status === 401 || dbCategories.status === 401 || dbStores.status === 401 || dbProducts.status === 401) {
            return this.props.history.push('/user/login?prev=/products');
        }

        if (dbCount.ok) {
            const count = dbCount.data.count;
            const pageCount = Math.ceil(Math.max(count / PRODUCTS_PER_PAGE, 1));
            this.setState({ count, pageCount });
        }

        if (dbCategories.ok) {
            const categories = [];
            dbCategories.data.forEach((c) => categories.push(<option key={c.id} value={c.id} name={c.name}>{c.name}</option>));
            categories.sort((a, b) => a.props.name.localeCompare(b.props.name));

            const addCategory = categories.length === 0 ? 0 : categories[0].props.value;
            this.setState({ categories, addCategory });
        }

        if (dbStores.ok) {
            const stores = [];
            dbStores.data.forEach((s) => stores.push(<option key={s.id} value={s.id} name={s.name}>{s.name}</option>));
            stores.sort((a, b) => a.props.name.localeCompare(b.props.name));

            const addStore = stores.length === 0 ? 0 : stores[0].props.value;
            this.setState({ stores, addStore });
        }

        if (dbProducts.ok) {
            const allProducts = [];
            dbProducts.data.forEach((p) => {
                allProducts.push(<Product key={p.id} id={p.id} product={p}/>);
            });
            this.getVisibleProducts(allProducts, this.state.search);
            this.setState({ allProducts });
        }

        [this.addPriceInput.current, this.addQuantityInput.current].forEach(r => {
            r.addEventListener('keyup', (e) => {
                if (e.which === 13) r.blur();
            });
            r.addEventListener('click', (e) => {
                if (e.target.value === '0.00' || e.target.value === '0') r.value = '';
            });
        });
    }

    getVisibleProducts(products, search) {
        const currentPage = this.state.search !== search ? 0 : this.state.currentPage;    
        const filteredProducts = products.filter((p) => {
            return p.props.product.name.toUpperCase().includes(search.toUpperCase())
        });

        const from = currentPage * PRODUCTS_PER_PAGE;
        const to = from + PRODUCTS_PER_PAGE;

        const visibleProducts = filteredProducts.slice(from, to);
        const pageCount = Math.ceil(filteredProducts.length / PRODUCTS_PER_PAGE);
        this.setState({ currentPage, search, visibleProducts, pageCount });
    }

    onQuantityChange(e) {
        let quantity = e.target.value;
        if (isNaN(quantity)) quantity = 0;

        quantity = Number(quantity);

        if (quantity < 0) quantity = 0;
        if (quantity > 9999) quantity = 9999;

        this.setState({ addQuantity: quantity });
    }

    onPriceChange(e) {
        let price = e.target.value;
        if (isNaN(price)) price = 0;

        price = Number(price);

        if (price < 0)      price = 0;
        if (price > 9999)   price = 9999;

        price = price.toFixed(2);
        this.setState({ addPrice: price });
    }

    onAddProduct() {
        const name = this.state.addName;
        const quantity = this.state.addQuantity;
        const quantity_type = this.state.addQuantityType;
        const price = this.state.addPrice;

        if (name.length < 2) {
            RensAlert.popup({
                title: 'Oops',
                text: 'The product name should be atleast 2 characters long',
                time: 4000
            });

            return;
        }

        if (name.length > 64) {
            RensAlert.popup({
                title: 'Oops',
                text: 'The product name is too long.',
                time: 4000
            });

            return;
        }

        if (quantity <= 0) {
            RensAlert.popup({
                title: 'Oops',
                text: 'The quantity of a product should be higher than 0',
                time: 4000
            });

            return;
        }

        if (quantity > 9999) {
            RensAlert.popup({
                title: 'Oops',
                text: 'The quantity of a product should be lower than 9999',
                time: 4000
            });

            return;
        }

        if (price < 0) {
            RensAlert.popup({
                title: 'Oops',
                text: 'The price of a product should be at least €0.00',
                time: 4000
            });

            return;
        }

        socket.emit('addProduct', { name, category_id: this.state.addCategory, store_id: this.state.addStore, quantity, quantity_type, price });
    }

    onPageNumberClick(index) {
        if (index === this.state.currentPage) return;
        this.setState({ currentPage: index }, () => this.getVisibleProducts(this.state.allProducts, this.state.search));
    }

    onLeftArrowClick() {
        const index = this.state.currentPage - 1;
        this.setState({ currentPage: index }, () => this.getVisibleProducts(this.state.allProducts, this.state.search));
    }

    onRightArrowClick() {
        const index = this.state.currentPage + 1;
        this.setState({ currentPage: index }, () => this.getVisibleProducts(this.state.allProducts, this.state.search));
    }

    render() {
        return (
            <div className="products">
                <h2>Products</h2>

                <div className="search">
                    <input 
                        type="text" 
                        placeholder='Search' 
                        value={this.state.search} 
                        onChange={(e) => this.getVisibleProducts(this.state.allProducts, e.target.value)}
                    />
                </div>

                <ul>{this.state.visibleProducts}</ul>

                <PageNav currentPage={this.state.currentPage} pageCount={this.state.pageCount} onPageClick={this.onPageNumberClick} onLeftClick={this.onLeftArrowClick} onRightClick={this.onRightArrowClick}/>

                <div className="add">
                    <input 
                        className="name" 
                        ref={this.addNameInput}
                        type="text" 
                        value={this.state.addName}
                        placeholder='Name'
                        onChange={(e) => this.setState({ addName: e.target.value })}
                    />

                    <div className="selects">
                        <select className="category" onChange={(e) => this.setState({ addCategory: e.target.value })}>
                            {this.state.categories}
                        </select>

                        <select className="store" onChange={(e) => this.setState({ addStore: e.target.value })}>
                            {this.state.stores}
                        </select>
                    </div>

                    <div className="quantity">
                        <input
                            className="quantity"
                            ref={this.addQuantityInput}
                            type="number"
                            value={this.state.addQuantity}
                            placeholder='Quantity'
                            onChange={(e) => this.setState({ addQuantity: e.target.value })}
                            onBlur={(e) => this.onQuantityChange(e)}
                        />

                        <select className="quantityType" onChange={(e) => this.setState({ addQuantityType: e.target.value })}>
                            <option value='piece'>piece(s)</option>
                            <option value='gram'>gram(s)</option>
                            <option value='kilogram'>kilogram(s)</option>
                            <option value='milliliter'>milliliter(s)</option>
                            <option value='liter'>liter(s)</option>
                        </select>
                    </div>

                    <div className="bottom">
                        <input 
                            className="price" 
                            ref={this.addPriceInput}
                            type="number" 
                            placeholder='Price' 
                            value={this.state.addPrice}
                            onChange={(e) => this.setState({ addPrice: e.target.value })}
                            onBlur={(e) => this.onPriceChange(e)}
                        />

                        <AddButton onClick={this.onAddProduct}/>
                    </div>
                </div>
            </div>
        );
    }
}