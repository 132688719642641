
import React from 'react';
import autobind from 'class-autobind';

export default class PageNav extends React.Component {
    constructor(props) {
        super(props);
        autobind(this);
    }

    onLeftArrowClick() {
        if (this.props.currentPage > 0) {
            this.props.onLeftClick();
        }
    }

    onRightArrowClick() {
        if (this.props.currentPage < this.props.pageCount - 1) {
            this.props.onRightClick();
        }
    }

    onPageClick(index) {
        this.props.onPageClick(index);
    }

    render() {
        const pages = [];

        let startCount = Math.max(this.props.currentPage - 2, 0);
        const endCount = Math.min(startCount + 5, this.props.pageCount);
        for (let i = startCount; i < endCount; i++) {
            pages.push(<div className={'number' + (this.props.currentPage === i ? ' current' : '')} key={i + Math.random()} onClick={() => this.onPageClick(i)}>{i + 1}</div>);
        }

        return (
            <div className="nav">
                <div className={'arrowLeft' + (this.props.currentPage !== 0 ? ' show' : '')} onClick={this.onLeftArrowClick}>
                    <div className="line one"></div>
                    <div className="line two"></div>
                </div>
                <div className="pageNumbers">
                    {pages}
                </div>
                <div className={'arrowRight' + (this.props.currentPage !== this.props.pageCount - 1 ? ' show' : '')} onClick={this.onRightArrowClick}>
                    <div className="line one"></div>
                    <div className="line two"></div>
                </div>
            </div>
        );
    }
}