
import React from 'react';
import autobind from 'class-autobind';

import ExpandButton from '../base/buttons/expandButton';

import priceIcon from '../../images/money.png';
import countIcon from '../../images/count.png';
import weightIcon from '../../images/weight.png';

const MONTHS = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
];

const WEEK_DAYS = [ 
    'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
];

function getNumberExtension(day) {
    if (day === 1) return 'st';
    if (day === 2) return 'nd';
    if (day === 3) return 'rd';

    return 'th';
}


export default class HistoryEntry extends React.Component {
    constructor(props) {
        super(props);
        autobind(this);

        this.state = {
            day: '',
            dayNumber: '',
            month: '',
            time: '',
            isExpanded: false
        };
    }

    componentDidMount() {
        const date = this.props.history.timestamp;
        const time = date.toTimeString();
        const timeShort = time.substr(0, time.lastIndexOf(':'));

        this.setState({
            day: WEEK_DAYS[date.getDay()],
            dayNumber: date.getDate(),
            month: MONTHS[date.getMonth()],
            time: timeShort
        });
    }

    onExpand() {
        this.setState({ isExpanded: !this.state.isExpanded });
    }

    getExpandedInfo() {
        const totalPrice = this.props.history.products.reduce((prev, cur) => prev + (cur.price * cur.count), 0).toFixed(2);
        const totalCount = this.props.history.products.reduce((prev, cur) => prev + cur.count, 0);
        const totalWeight = this.props.history.products.reduce((prev, cur) => {
            let weight = 0;
            if (cur.quantity_type === 'gram' || cur.quantity_type === 'milliliter') weight = cur.quantity;
            else if (cur.quantity_type === 'liter') weight = cur.quantity * 1000;
            return prev + (weight * cur.count);
        }, 0);

        return (
            <div className="info">
                <div className="count">
                    <img src={countIcon} alt="count"/>
                    
                    <div className="text">
                        <p>Products</p>
                        <p>{totalCount}</p>
                    </div>                            
                </div>

                <div className="weight">
                    <img src={weightIcon} alt="weight"/>

                    <div className="text">
                        <p>Weight</p>
                        <p>{totalWeight}g ({(totalWeight / 1000).toFixed(2)}kg)</p>
                    </div>
                </div>

                <div className="price">
                    <img src={priceIcon} alt="price"/>

                    <div className="text">
                        <p>Price</p>
                        <p>&euro; {totalPrice}</p>
                    </div> 
                </div>
            </div>
        );
    }

    render() {
        const className = 'history entry' + (this.props.first ? ' first' : '') + (this.props.last ? ' last' : '');

        return (
            <div className={className}>
                <div className="top">
                    <ExpandButton onClick={this.onExpand} expanded={this.state.isExpanded}/>
                    <p>{this.state.day} {this.state.dayNumber}{getNumberExtension(this.state.dayNumber)} of {this.state.month} ({this.state.time})</p>
                </div>

                {this.state.isExpanded ? this.getExpandedInfo() : null}
            </div>
        );
    }
}