
import React from 'react';

import db from '../utils/database';
import autobind from 'class-autobind';
import socket from '../utils/sockets';

import ColorPicker from '../base/previewColorPicker';

import '../../scss/editCategory.scss';
import { getIdFromUrl } from '../utils/util';

export default class EditCategory extends React.Component {
    constructor() {
        super();
        autobind(this);

        this.state = {
            id: getIdFromUrl(),
            name: '',
            prevColor: '#e7e7e7',
            color: '#e7e7e7',
            showPicker: false,
            error: false,
            errorMsg: ''
        };
    }

    async componentDidMount() {
        const dbCat = await db.getCategory(this.state.id);
        if (dbCat.ok) {
            this.setState({ name: dbCat.data.name, color: dbCat.data.color, error: false, errorMsg: '' });
        }
        else {
            this.setState({ errorMsg: dbCat.data, error: true });
        }
    }

    onEditClick() {
        socket.emit('editCategory', { id: this.state.id, name: this.state.name, color: this.state.color });
    }

    onColorChange(color) {
        this.setState({ color: color.hex });
    }
    
    render() {
        if (this.state.error) return (
            <div className="edit category">
                <h2>Edit Category</h2>

                <h3 className="error">{this.state.errorMsg}</h3>
            </div>
        );

        return (
            <div className="edit category">
                <h2>Edit Category</h2>

                <div className="content">
                    <input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })}/>
                    <ColorPicker 
                        editing={this.state.showPicker} color={this.state.color} onColorChange={this.onColorChange} 
                        onClickColor={() => this.setState({ showPicker: true })} onClickOutside={() => this.setState({ showPicker: false })}
                    />
                </div>

                <button onClick={this.onEditClick}>Edit</button>
            </div>
        );
    }
}