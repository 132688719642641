
import React from 'react';

import AddButton from '../base/buttons/addButton';
import autobind from 'class-autobind';

import socket from '../utils/sockets';
import RensAlert from '../../rensAlert/rensAlert';

import ColorPicker from '../base/previewColorPicker';

import db from '../utils/database';

import Category from './category';

import '../../scss/categories.scss';

export default class Categories extends React.Component {
    constructor(props) {
        super(props);
        autobind(this);

        this.state = {
            search: '',
            addName: '',
            prevColor: '#e7e7e7',
            addColor: '#e7e7e7',
            showPicker: false,
            allCategories: [],
            categories: []
        };

        socket.on('categoryAdded', (category) => {
            const allCategories = this.state.allCategories;

            allCategories.push(<Category key={category.id} id={category.id} name={category.name} color={category.color}/>);
            allCategories.sort((a, b) => a.props.name.localeCompare(b.props.name));
            this.setState({ allCategories, addName: '' }, () => this.filterProducts(this.state.search));
        });

        socket.on('categoryRemoved', (id) => {
            const allCategories = this.state.allCategories;
            
            allCategories.splice(allCategories.findIndex((c) => c.props.id === id), 1);

            this.setState({ allCategories }, () => this.filterProducts(this.state.search));
        });
    }

    async componentDidMount() {
        const dbCategories = await db.getAllCategories();
        if (dbCategories.ok) {
            const allCategories = [];
            dbCategories.data.forEach((c) => allCategories.push(<Category key={c.id} id={c.id} name={c.name} color={c.color}/>));

            allCategories.sort((a, b) => a.props.name.localeCompare(b.props.name));
            this.setState({ allCategories, categories: allCategories });
        }
    }

    onAddCategory() {
        const name = this.state.addName;

        if (name.length < 2) {
            RensAlert.popup({
                title: 'Oops',
                text: 'The category name should be atleast 2 characters long',
                time: 4000
            });

            return;
        }

        if (name.length > 64) {
            RensAlert.popup({
                title: 'Oops',
                text: 'The category name is too long.',
                time: 4000
            });

            return;
        }

        socket.emit('addCategory', { name, color: this.state.addColor });
    }

    filterProducts(search) {
        const categories = this.state.allCategories.filter((p) => p.props.name.toUpperCase().includes(search.toUpperCase()));
        this.setState({ search, categories });
    }

    onColorChange(c) {
        this.setState({ addColor: c.hex });
    }

    render() {
        return (
            <div className="categories">
                <h2>Categories</h2>

                <div className="search">
                    <input 
                        type="text" 
                        placeholder='Search' 
                        value={this.state.search} 
                        onChange={(e) => this.filterProducts(e.target.value)}
                    />
                </div>

                <ul>{this.state.categories}</ul>

                <div className="add">
                    <input 
                        className="name" 
                        ref={this.addNameInput}
                        type="text" 
                        value={this.state.addName}
                        placeholder='Name'
                        onChange={(e) => this.setState({ addName: e.target.value })}
                    />

                    <ColorPicker 
                        editing={this.state.showPicker} color={this.state.addColor} onColorChange={this.onColorChange} 
                        onClickColor={() => this.setState({ showPicker: true })} onClickOutside={() => this.setState({ showPicker: false })}
                    />

                    <AddButton onClick={this.onAddCategory}/>
                </div>
            </div>
        );
    }
}