
import React from 'react';
import autobind from 'class-autobind';
import socket from '../utils/sockets';

import { shortQuantity, shortQuantityType } from '../utils/util';

export default class Product extends React.Component {
    constructor(props) {
        super(props);
        autobind(this);
    }

    onTextClick() {
        socket.emit('checkProduct', { id: this.props.product.id });
    }

    onMinClick() {
        if (this.props.product.count === 1) {
            socket.emit('removeFromList', { id: this.props.product.id });
        }
        else {
            socket.emit('decreaseCount', { id: this.props.product.id });
        }
    }

    onPlusClick() {
        socket.emit('increaseCount', { id: this.props.product.id });
    }

    render() {
        return (
            <div style={{ backgroundColor: this.props.color }} className={`product ${this.props.product.name}` + (this.props.product.checked ? ' checked' : '')}>
                <p className="text" onClick={this.onTextClick}>{shortQuantity(this.props.product.quantity, this.props.product.quantity_type)} {shortQuantityType(this.props.product.quantity_type)} {this.props.product.name}</p>

                <div className="count">
                    <div className="min" onClick={this.onMinClick}>
                        <span className="line"></span>
                    </div>
                    <span>{this.props.product.count}</span>
                    <div className="plus" onClick={this.onPlusClick}>
                        <span className="line one"></span>
                        <span className="line two"></span>
                    </div>
                </div>
            </div>
        );
    }
}