
export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function lightenDarkenColor(col, amt) {
    let usePound = false;
    if (col[0] === "#" ) {
        col = col.slice(1);
        usePound = true;
    }

    let num = parseInt(col,16);

    let r = (num >> 16) + amt;

    if ( r > 255 ) r = 255;
    else if  (r < 0) r = 0;

    let b = ((num >> 8) & 0x00FF) + amt;

    if ( b > 255 ) b = 255;
    else if  (b < 0) b = 0;

    let g = (num & 0x0000FF) + amt;

    if ( g > 255 ) g = 255;
    else if  ( g < 0 ) g = 0;

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

export function shortQuantity(quantity, quantity_type) {
    return (quantity_type === 'piece' && quantity) === 1 ? '' : quantity; 
}

export function shortQuantityType(quantity_type) {
    switch (quantity_type) {
        case 'kilogram': return 'kg';
        case 'milliliter': return 'ml';
        case 'piece': return '';
        case 'liter': return 'l';
        case 'gram': return 'g';
        default: return quantity_type;
    }
}

export function parseJwt(token) {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
}

export function getIdFromUrl() {
    let id = null;
    const urlParts = window.location.href.split('/');
    if (urlParts && urlParts.length > 0) {
        id = Number(urlParts[urlParts.length - 1]);
    }
    return id;
}

export function getParam(name) {
    const urlParts = window.location.href.split('?');
    if (urlParts.length <= 1) return null;

    const params = new URLSearchParams(urlParts[1]);
    return params.get(name);
}