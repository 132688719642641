import io from 'socket.io-client';
import RensAlert from '../../rensAlert/rensAlert';
import userContext from '../../context/userContext';
import ContextManager from '../../context/contextManager';
import Logger from './logger';

const SocketConnectionState = {
    Disconnected: 0,
    Reconnecting: 1,
    Connected: 3
};

class Socket {
    constructor() {
        this.socket = io(process.env.REACT_APP_URL, { path: '/sockets' });
        this.connectionState = SocketConnectionState.Disconnected;

        this.logger = new Logger("SOCKET");
        this.init();
    }

    init() {
        this.socket.on('onError', (msg) => {
            this.logger.debug('[SOCKET] Received on "Error" with args: ' + msg);

            RensAlert.popup({
                title: 'Oops',
                text: msg,
                time: 4000
            });
        });

        this.socket.on('connect', () => {
            this.logger.debug('[SOCKET] Connect');
            this.login(userContext.jwt);
        });

        this.socket.on('disconnect', () => {
            this.logger.debug('[SOCKET] Disconnect');
            if (this.connectionState === SocketConnectionState.Connected) {
                // This user lost connection after being connected
                // meaning we should retrieve products again after reconnection to make sure
                // we didn't miss any updates that happened in between
                this.connectionState = SocketConnectionState.Reconnecting;
            }
        });

        this.socket.on('loginSucces', () => {
            this.logger.debug('[SOCKET] Login Success');
            if (this.connectionState === SocketConnectionState.Reconnecting) {
                // This user had lost connection but is now back
                // we should update their shoppinglist to ensure that everything is up-to-date
                this.logger.debug('[SOCKET] Reconnected');
                ContextManager.getList();
            }

            this.connectionState = SocketConnectionState.Connected;
        });
    }

    login(jwt) {
        if (this.connectionState !== SocketConnectionState.Connected) {
            this.socket.emit('login', jwt);
        }
    }

    logout() {
        this.connectionState = SocketConnectionState.Disconnected;
        this.socket.emit('logout');
    }

    on(path, handlerFunc) {
        this.socket.on(path, (...args) => {
            this.logger.debug(`[SOCKET] Received on "${path}" with args: ` + args.map(a => JSON.stringify(a)).join(' '));
            handlerFunc(...args);
        });
    }

    emit(path, data) {
        this.socket.emit(path, { uuid: userContext.user.uuid, ...data } );
    }
}

const socket = new Socket();
export default socket;